<template>
  <header class="layout-cabinet_header">
    <div class="layout-cabinet_header-inner">
      <div class="layout-cabinet_header-wrap w-100">
        <HeaderLogos />
      </div>

      <HeaderMenuButton
        v-if="windowWidth <= 992"
        @click-button="toggleMobileMenu"
        :open-menu="openMenu"
      />
    </div>

    <HeaderMenu @close="closeMobileMenu" :is-open="openMenu" :is-mobile="windowWidth <= 992" />
  </header>
</template>

<script setup lang="ts">
import { ref } from 'vue'
import HeaderLogos from '@/entities/Layout/ui/header/HeaderLogos.vue'
import HeaderMenu from '@/entities/Layout/ui/header/HeaderMenu.vue'
import HeaderMenuButton from '@/entities/Layout/ui/header/HeaderMenuButton.vue'
import { useBodyScrollLock } from '@/shared/composables/useBodyScrollLock'
import { useWindowWidth } from '@/shared/composables/useWindowWidth'

const { windowWidth } = useWindowWidth()
const { lockScroll, unlockScroll } = useBodyScrollLock()

const openMenu = ref(false)

const closeMobileMenu = () => {
  openMenu.value = false
  unlockScroll()
}

const toggleMobileMenu = () => {
  openMenu.value = !openMenu.value

  if (openMenu.value) {
    lockScroll()
  } else {
    unlockScroll()
  }
}
</script>

<style lang="scss" scoped>
.layout-cabinet_header {
  &-wrap {
    display: block;
    @include lg() {
      display: flex;
    }
  }

  &-name-text {
    margin-bottom: 16px;

    @include lg() {
      margin-bottom: 0 !important;
    }
  }
}
</style>
