import * as Sentry from '@sentry/vue'
import { ref } from 'vue'
import type { CompanyReceiveEvent, CompanySendEvent } from '../types/ws/pretest/company'
import type { FreeSendEvent, FreeReceiveEvent } from '../types/ws/pretest/free'

interface WsReceiveEvent extends FreeReceiveEvent, CompanyReceiveEvent {}
interface SendEvent extends FreeSendEvent, CompanySendEvent {}

type Listener<T extends keyof WsReceiveEvent> = (data: WsReceiveEvent[T]) => void
type Listeners<T extends keyof WsReceiveEvent> = Array<Listener<T>>

const state = ref<{
  connected: boolean
  socket: WebSocket | null
  listeners: {
    [key in keyof WsReceiveEvent]?: Listeners<key>
  }
}>({
  connected: false,
  socket: null,
  listeners: {}
})

export function useWebsockets() {
  function init() {
    const URL = import.meta.env.VITE_WEBSOCKET_URL + '?token=' + localStorage.getItem('token')
    state.value.socket = new WebSocket(URL)

    state.value.socket.onmessage = (event) => {
      const data: {
        event: keyof WsReceiveEvent
        data: WsReceiveEvent[keyof WsReceiveEvent]
      } = JSON.parse(event.data)

      if (import.meta.env.VITE_NODE_ENV === 'dev') {
        console.log(data)
      }

      // @ts-ignore
      state.value.listeners[data.event]?.forEach((listener) => listener(data.data))
    }

    state.value.socket.onopen = () => {
      log('connected')
      state.value.connected = true
    }

    state.value.socket.onclose = (event) => {
      log(`closed. reason: ${event.reason}`)
      state.value.connected = false
      if (localStorage.getItem('token')) {
        setTimeout(() => init(), 1000)
      }
    }

    state.value.socket.onerror = (event) => {
      log(`error: ${event}`)
      throttleSendSentryEvent('WEBSOCKET ERROR!', event)
    }
  }

  let lastErrorTimestamp = 0
  const ERROR_THROTTLE_TIME = 10 * 60 * 1000 // 10 minutes
  function throttleSendSentryEvent(message: string, data: any) {
    const now = Date.now()
    if (now - lastErrorTimestamp > ERROR_THROTTLE_TIME) {
      lastErrorTimestamp = now
      sendSentryEvent(message, data)
    }
  }

  function sendSentryEvent(message: string, data: any) {
    Sentry.captureMessage(message, {
      level: 'fatal',
      extra: {
        timestamp: data.timeStamp,
        type: data.type
      }
    })
  }

  function log(message: string) {
    if (import.meta.env.VITE_NODE_ENV === 'dev') {
      console.log(`[WS] ${message}`)
    }
  }

  function close() {
    state.value.socket?.close()
  }

  function on<T extends keyof WsReceiveEvent>(
    event: T,
    handler: (data: WsReceiveEvent[T]) => void
  ) {
    log(`add listener for event "${event}"`)

    // @ts-ignore
    state.value.listeners[event] = [
      ...((state.value.listeners[event] as Listeners<T>) ?? []),
      handler
    ]

    return () => off(event, handler)
  }

  function off<T extends keyof WsReceiveEvent>(event: T, handler: Listener<T>) {
    log(`remove listener for event "${event}"`)

    // @ts-ignore
    state.value.listeners[event] = (state.value.listeners[event] as Listeners<T>)?.filter(
      (h) => h !== handler
    )
  }

  function offAll<T extends keyof WsReceiveEvent>(event: T) {
    log(`remove all listeners for event "${event}"`)
    state.value.listeners[event] = []
  }

  function waitForConnection() {
    return new Promise((resolve) => {
      const interval = setInterval(() => {
        if (state.value.connected) {
          clearInterval(interval)
          resolve(null)
        }
      }, 100)
    })
  }

  async function send<T extends keyof SendEvent>(event: T, data: SendEvent[T] = {}) {
    await waitForConnection()
    log(`send event "${event}" with ${JSON.stringify(data)}`)
    state.value.socket?.send(JSON.stringify({ event, ...data }))
  }

  return {
    isConnect: state.value.connected,
    init,
    close,
    send,
    on,
    off,
    offAll
  }
}
