export default [
  {
    path: '/checkout',
    name: 'checkout',
    component: () => import('@/modules/general/Checkout/pages/CheckoutView.vue'),
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/checkout/success',
    name: 'checkout-success',
    component: () => import('@/modules/general/Checkout/pages/CheckoutSuccessView.vue'),
    meta: {
      layout: 'default'
    }
  }
]
