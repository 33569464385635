import { UserSpace } from '@/entities/User/types'

export default [
  {
    path: '/partners',
    name: 'partners',
    meta: {
      space: [
        UserSpace.CorporateStudent,
        UserSpace.Parent,
        UserSpace.CorporateHr,
        UserSpace.Student,
        UserSpace.StudentTeacherTraining
      ]
    },
    component: () => import('@/entities/Layout/ui/SuspenseComponent.vue'),
    redirect: { name: 'partners-gifts-and-discounts' },
    children: [
      {
        path: 'gifts-and-discounts',
        name: 'partners-gifts-and-discounts',
        component: () => import('@/modules/general/Partners/pages/GiftsAndDiscountsView.vue')
      }
    ]
  }
]
