<template>
  <div class="language-switcher-select_wrapper">
    <div
      @blur="open = false"
      class="language-switcher-select"
      :class="{ open: open }"
      data-testid="language-switcher-select"
    >
      <div
        @click="open = !open"
        class="selected"
        :class="{ open: open }"
        data-testid="language-switcher-selected"
      >
        <span class="p-lead-semi-bold" data-testid="language-switcher-selected-text">{{
          $t('language_switcher_' + selected)
        }}</span>
        <vue-feather type="chevron-down" size="24" stroke="#6E6B7B"></vue-feather>
      </div>
      <div class="items" :class="{ selectHide: !open }" data-testid="language-switcher-items">
        <div
          v-for="locale in $i18n.availableLocales.filter((el) => el !== $i18n.locale)"
          :key="locale"
          @click="setLocale(locale)"
          class="item"
          data-testid="language-switcher-item"
        >
          <span class="p-lead-semi-bold" data-testid="language-switcher-item-text">{{
            $t('language_switcher_' + locale)
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useLanguageSwitcher } from '@/shared/composables/useLanguageSwitcher'

const { open, selected, setLocale } = useLanguageSwitcher()
</script>

<style lang="scss" scoped>
.language-switcher-select {
  position: absolute;
  width: 174px;
  box-shadow: 0 4px 24px 0 #0000000f;
  outline: none;
  cursor: pointer;
  text-align: left;
  height: 47px;
  transition: all 0.3s ease-in-out;
  right: 0;

  &.open {
    height: 86px;
  }

  &_wrapper {
    height: 47px;
    position: relative;
    width: 100%;
  }

  .selected {
    position: relative;
    display: flex;
    z-index: 3;
    justify-content: space-between;
    align-items: center;
    padding: 6px 10px;
    background: #ffffff;
    border-radius: 4px;

    span {
      padding: 4px 8px;
    }

    svg {
      width: 24px;
      height: 24px;
      margin-left: 10px;
      transition: all 0.3s ease-in-out;
    }

    &.open {
      border-radius: 4px 4px 0 0;

      svg {
        transform: rotate(180deg);
      }
    }
  }

  .items {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 1;
    background-color: #ffffff;
    border-radius: 0 0 4px 4px;
    transform: translateY(0);
    transition: all 0.3s ease-in-out;
    overflow: hidden;

    &.selectHide {
      transform: translateY(-100%);
      transition: all 0.3s ease-in-out;
    }

    .item {
      padding: 6px 10px;
      cursor: pointer;
      user-select: none;

      span {
        padding: 4px 8px;
        border-radius: 4px;
        transition: all 0.3s ease-in-out;
      }

      &:hover {
        span {
          background: rgba(43, 77, 171, 0.12);
        }
      }
    }
  }
}
</style>
