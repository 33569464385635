import * as Sentry from '@sentry/vue'
import { browserTracingIntegration } from '@sentry/vue'
import { createPinia } from 'pinia'
import { createApp } from 'vue'
import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'
import App from './index.vue'
import 'bootstrap/dist/js/bootstrap.js'

// import plugins
import { i18n, router, vueFeather } from './providers'

// import styles
import '@/app/assets/styles/index.scss'

// Vite production error handling
if (import.meta.env.VITE_NODE_ENV === 'production') {
  window.addEventListener('vite:preloadError', (event) => {
    event.preventDefault()
    window.location.reload()
  })
}

// APP
export const app = createApp(App)

// Sentry
Sentry.init({
  app,
  dsn: import.meta.env.VITE_SENTRY_DSN, // where to send the events
  enabled: import.meta.env.VITE_NODE_ENV === 'production',
  integrations: [
    browserTracingIntegration({ router, routeLabel: 'path' }),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
      networkDetailAllowUrls: [import.meta.env.VITE_API_URL]
    }),
    Sentry.httpClientIntegration() // catch errors from httpClient (500 - 599)
  ],
  attachStacktrace: true, // attach stack trace to error
  replaysSessionSampleRate: 0, // Capture Replay for 0% of all sessions
  replaysOnErrorSampleRate: 1, // plus for 100% of sessions with an error
  tracesSampleRate: 0.2, // 20% of transactions for performance will be sent to Sentry
  sampleRate: 0.25, // 25% of all errors will be sent to Sentry
  sendDefaultPii: true, // capturing headers and cookies (need for httpClient)
  trackComponents: true, // track Vue components rendering
  tracePropagationTargets: [/^https:\/\/grade-nexus\.com\.ua/] // send traces to Sentry for requests to this domain (api requests)
})

app.use(createPinia())
app.use(router)
app.use(i18n)
app.component(vueFeather.name, vueFeather)
app.use(Toast)
