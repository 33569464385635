import { ref } from 'vue'
import { useI18n } from 'vue-i18n'

export function useLanguageSwitcher() {
  const language = useI18n()
  const selected = ref(language.locale)
  const open = ref(false)

  function setLocale(locale: string) {
    localStorage.setItem('appLanguage', locale)
    language.locale.value = locale
    selected.value = locale
    open.value = false
  }
  return { open, selected, setLocale }
}
