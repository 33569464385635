import * as http from '@/shared/http'
import {
  deleteUserEmailFromCookies,
  deleteUserNameFromCookies,
  setUserEmailToCookies,
  setUserNameToCookies
} from '@/shared/lib/cookies'
import type { UserResponse, LoginUserResponse, UserCertificatesResponse } from '../types'

export const loginAuthUser = (email: string, password: string) => {
  return http
    .request<LoginUserResponse>(
      {
        url: `api/v1/cabinet-auth`,
        method: 'post',
        data: {
          email: email,
          password: password,
          userAgent: window.navigator.userAgent
        }
      },
      { showErrorToast: false }
    )
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem('token', response.data.token)
        setUserEmailToCookies(response.data.user.data.email)
        setUserNameToCookies(response.data.user.data.name)
      }

      return response.data.user.data
    })
}

export const loginUserWithGoogle = (token: string | null) => {
  return http
    .request<LoginUserResponse>({
      url: `api/v1/cabinet-google-auth`,
      method: 'post',
      data: {
        token: token,
        userAgent: window.navigator.userAgent
      }
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem('token', response.data.token)
        setUserEmailToCookies(response.data.user.data.email)
      }

      return response.data.user.data
    })
}

export const logoutUser = () => {
  return http
    .request({
      url: `api/v1/cabinet-logout`,
      method: 'post'
    })
    .then(() => {
      deleteUserEmailFromCookies()
      deleteUserNameFromCookies()
      localStorage.removeItem('user')
      localStorage.removeItem('token')
    })
}

export const registerAuthUser = (email: string) => {
  return http.request(
    {
      url: `api/v1/cabinet-register`,
      method: 'post',
      data: {
        email: email
      }
    },
    { showErrorToast: false }
  )
}

export const resetUserPassword = (email: string) => {
  return http.request({
    url: `api/v1/cabinet-reset-password`,
    method: 'post',
    data: {
      email: email
    }
  })
}

export const getUser = () => {
  return http
    .request<UserResponse>({
      url: `api/v1/cabinet-user`,
      method: 'get'
    })
    .then((response) => response.data.data)
}

export const updateUserCallBack = (callbackType: string, content: string) => {
  return http.request({
    url: `api/v1/cabinet-user-update-callback`,
    method: 'post',
    data: {
      callbackType: callbackType,
      phone: content
    }
  })
}

export const changeUserProfileInfo = (name: string, nameEn: string, phone: string) => {
  return http
    .request<UserResponse>({
      url: `api/v1/cabinet-update-user-data`,
      method: 'post',
      data: {
        name: name,
        nameEn: nameEn,
        phone: phone
      }
    })
    .then((response) => response)
}

export const changeUserPassword = (password: string) => {
  return http
    .request<UserResponse>({
      url: `api/v1/cabinet-change-password`,
      method: 'post',
      data: {
        password: password
      }
    })
    .then((response) => response.data.data)
}

export const changeUserEngName = (name: string) => {
  return http
    .request({
      url: `api/v1/cabinet-update-student-name-en`,
      method: 'post',
      data: {
        name_en: name
      }
    })
    .then((response) => response)
}

export const getUserCertificates = (page: number = 1, itemsPerPage: number = 12) => {
  return http
    .request<UserCertificatesResponse>({
      url: `api/v1/user-certificates?perPage=${itemsPerPage}&page=${page}`,
      method: 'get'
    })
    .then((response) => response.data)
}
