import { useRouter } from 'vue-router'
import { useWebsockets } from '@/shared/composables/useWebsockets'
import { useAuthStore } from '../../model'

const ws = useWebsockets()

export function useLogoutUser() {
  const authStore = useAuthStore()
  const router = useRouter()

  function logoutUser() {
    authStore.logout().then(() => {
      router.push({ name: 'login' })
      ws.close()
    })
  }

  return {
    logoutUser
  }
}
