/**
 * Please, DON`T CHANGE route paths (url) in this file!
 * These routes are used in Email templates!!!
 */
export default [
  {
    path: '/teacherTraining/webinars',
    name: 'teacher-webinars',
    meta: {
      space: 'student_teacher_training'
    },
    component: () => import('@/modules/teacherTraining/pages/webinars/WebinarsListView.vue')
  },
  {
    path: '/teacherTraining/webinars/:id',
    name: 'teacher-webinar-info',
    meta: {
      space: 'student_teacher_training'
    },
    component: () => import('@/modules/teacherTraining/pages/webinars/WebinarInfoView.vue')
  },
  {
    path: '/teacherTraining/webinars/video/:id',
    name: 'teacher-webinar-video',
    meta: {
      space: 'student_teacher_training'
    },
    component: () => import('@/modules/teacherTraining/pages/webinars/WebinarVideoView.vue')
  },
  {
    path: '/teacherTraining/webinars/certificate/:id',
    name: 'teacher-webinar-certificate',
    meta: {
      space: 'student_teacher_training'
    },
    component: () => import('@/modules/teacherTraining/pages/webinars/WebinarCertificateView.vue')
  },
  {
    path: '/teacherTraining/conferences',
    name: 'teacher-conferences',
    meta: {
      space: 'student_teacher_training'
    },
    component: () => import('@/modules/teacherTraining/pages/conferences/ConferencesListView.vue')
  },
  {
    path: '/teacherTraining/conferences/:id',
    name: 'teacher-conference-info',
    meta: {
      space: 'student_teacher_training'
    },
    component: () => import('@/modules/teacherTraining/pages/conferences/ConferenceInfoView.vue')
  },
  {
    path: '/teacherTraining/conferences/video/:id',
    name: 'teacher-conference-video',
    meta: {
      space: 'student_teacher_training'
    },
    component: () => import('@/modules/teacherTraining/pages/conferences/ConferenceVideoView.vue')
  },
  {
    path: '/teacherTraining/conferences/certificate/:id',
    name: 'teacher-conference-certificate',
    meta: {
      space: 'student_teacher_training'
    },
    component: () =>
      import('@/modules/teacherTraining/pages/conferences/ConferenceCertificateView.vue')
  },
  {
    path: '/teacherTraining/workshops',
    name: 'teacher-workshops',
    meta: {
      space: 'student_teacher_training'
    },
    component: () => import('@/modules/teacherTraining/pages/workshops/WorkshopsListView.vue')
  },
  {
    path: '/teacherTraining/workshops/:id',
    name: 'teacher-workshop-info',
    meta: {
      space: 'student_teacher_training'
    },
    component: () => import('@/modules/teacherTraining/pages/workshops/WorkshopInfoView.vue')
  },
  {
    path: '/teacherTraining/workshops/video/:id',
    name: 'teacher-workshop-video',
    meta: {
      space: 'student_teacher_training'
    },
    component: () => import('@/modules/teacherTraining/pages/workshops/WorkshopVideoView.vue')
  },
  {
    path: '/teacherTraining/workshops/certificate/:id',
    name: 'teacher-workshop-certificate',
    meta: {
      space: 'student_teacher_training'
    },
    component: () => import('@/modules/teacherTraining/pages/workshops/WorkshopCertificateView.vue')
  },
  {
    path: '/teacherTraining',
    meta: {
      space: 'student_teacher_training'
    },
    component: () => import('@/entities/Layout/ui/SuspenseComponent.vue'),
    redirect: { name: 'teacher-training-groups' },
    children: [
      {
        path: 'tkt-groups',
        name: 'teacher-training-groups',
        component: () =>
          import('@/modules/teacherTraining/Groups/List/pages/TraineeGroupListView.vue')
      },
      {
        path: 'tkt-groups/:id',
        name: 'teacher-training-group',
        component: () =>
          import('@/modules/teacherTraining/Groups/Layout/widgets/ui/GroupLayout.vue'),
        redirect: { name: 'teacher-training-group-general' },
        children: [
          {
            path: 'general',
            name: 'teacher-training-group-general',
            component: () =>
              import('@/modules/teacherTraining/Groups/General/pages/GeneralView.vue')
          },
          {
            path: 'schedule',
            name: 'teacher-training-group-schedule',
            component: () =>
              import('@/modules/teacherTraining/Groups/Schedule/List/pages/ScheduleView.vue')
          },
          {
            path: 'schedule/lesson/:lessonId',
            name: 'teacher-training-group-lesson',
            component: () =>
              import('@/modules/teacherTraining/Groups/Schedule/Lesson/pages/TraineeLessonView.vue')
          },
          {
            path: 'useful-links',
            name: 'teacher-training-group-useful-links',
            component: () =>
              import('@/modules/teacherTraining/Groups/UsefulLinks/pages/UsefulLinksView.vue')
          },
          {
            path: 'course-summary',
            name: 'teacher-training-group-course-summary',
            component: () =>
              import('@/modules/teacherTraining/Groups/CourseSummary/pages/CourseSummaryView.vue')
          }
        ]
      }
    ]
  }
]
