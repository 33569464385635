import { defineStore } from 'pinia'
import { useWebsockets } from '@/shared/composables/useWebsockets'
import { logoutUser, getUser } from '../api'
import type { User } from '../types'

const ws = useWebsockets()

export const useAuthStore = defineStore({
  id: 'user',
  state: () => ({
    user: <User | null>JSON.parse(localStorage.getItem('user') || '{}'),
    loggedIn: !!localStorage.getItem('user'),
    returnUrl: JSON.parse(localStorage.getItem('returnUrl') || '{}')
  }),
  actions: {
    setUser(user: User) {
      this.loggedIn = true
      this.user = user
      localStorage.setItem('user', JSON.stringify(user))
    },
    logout() {
      return logoutUser().then(() => {
        this.loggedIn = false
        this.user = null
        ws.close()
      })
    },
    async updateUser() {
      return getUser().then((user: User) => this.setUser(user))
    }
  }
})
