import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import * as yup from 'yup'

export function useEmailValid() {
  const { t } = useI18n()
  const email = ref('')
  const validationsSchema = yup.object().shape({
    email: yup.string().email(t('form.email_valid')).required(t('form.email_required'))
  })
  const emailError = ref('')

  function validateEmail(submitFunction: any) {
    validationsSchema
      .validate({ email: email.value })
      .then(() => {
        submitFunction()
      })
      .catch((err) => {
        emailError.value = err.errors[0]
      })
  }

  function focusEmailInput() {
    emailError.value = ''
  }

  return {
    email,
    emailError,
    validateEmail,
    focusEmailInput
  }
}
