export default [
  {
    path: '/corporate-manager/students',
    name: 'corporate-manager-students',
    component: () => import('@/modules/corporateManager/Students/pages/StudentListView.vue'),
    meta: {
      space: 'corporate_hr'
    }
  },
  {
    path: '/corporate-manager/students/:id',
    name: 'corporate-manager-student',
    component: () => import('@/modules/corporateManager/Students/pages/SingleStudentView.vue'),
    meta: {
      space: 'corporate_hr'
    },
    children: [
      {
        path: 'learning-activity',
        name: 'corporate-manager-student-learning-activity',
        component: () =>
          import('@/modules/corporateManager/Students/widgets/ui/StudentSingleLearningActivity.vue')
      },
      {
        path: 'placements-tests',
        name: 'corporate-manager-student-placements-tests',
        component: () =>
          import('@/modules/corporateManager/Students/widgets/ui/TestInfoCardList.vue')
      },
      {
        path: 'certificates',
        name: 'corporate-manager-student-certificates',
        component: () =>
          import('@/modules/corporateManager/Students/widgets/ui/StudentSingleCertificate.vue')
      }
    ]
  },
  {
    path: '/corporate-manager/students/:id/results/:userTestVariantId',
    name: 'corporate-manager-student-results',
    redirect: { name: 'corporate-manager-student-results-general' },
    component: () =>
      import('@/modules/corporateManager/Students/pages/SingleStudentResultView.vue'),
    meta: {
      space: 'corporate_hr'
    },
    children: [
      {
        path: 'general',
        name: 'corporate-manager-student-results-general',
        component: () => import('@/modules/pretests/widgets/TestResult/ui/GeneralResult.vue'),
        props: { isCorporateManagerView: true }
      },
      {
        path: 'grammar',
        name: 'corporate-manager-student-results-grammar',
        component: () => import('@/modules/pretests/widgets/TestResult/ui/GrammarResult.vue')
      },
      {
        path: 'writing',
        name: 'corporate-manager-student-results-writing',
        component: () => import('@/modules/pretests/widgets/TestResult/ui/WritingResult.vue')
      },
      {
        path: 'reading',
        name: 'corporate-manager-student-results-reading',
        component: () => import('@/modules/pretests/widgets/TestResult/ui/ReadingResult.vue')
      },
      {
        path: 'speaking',
        name: 'corporate-manager-student-results-speaking',
        component: () => import('@/modules/pretests/widgets/TestResult/ui/SpeakingResult.vue')
      }
    ]
  },
  {
    path: '/corporate-manager/students/:id/paid-results',
    name: 'corporate-manager-student-paid-results',
    redirect: { name: 'corporate-manager-student-paid-results-general' },
    component: () => import('@/modules/corporateManager/Students/pages/BaseLayout.vue'),
    meta: {
      space: 'corporate_hr'
    },
    children: [
      {
        path: ':userTestVariantId',
        name: 'corporate-manager-student-paid-results-test',
        redirect: { name: 'corporate-manager-student-paid-results-general' },
        component: () =>
          import('@/modules/corporateManager/Students/pages/PaidTestResult/Layout.vue'),
        children: [
          {
            path: 'general',
            name: 'corporate-manager-student-paid-results-general',
            component: () =>
              import('@/modules/corporateManager/Students/pages/PaidTestResult/GeneralView.vue')
          },
          {
            path: 'grammar',
            name: 'corporate-manager-student-paid-results-grammar',
            component: () =>
              import('@/modules/corporateManager/Students/pages/PaidTestResult/GrammarView.vue')
          },
          {
            path: 'writing',
            name: 'corporate-manager-student-paid-results-writing',
            component: () =>
              import('@/modules/corporateManager/Students/pages/PaidTestResult/WritingView.vue')
          },
          {
            path: 'speaking',
            name: 'corporate-manager-student-paid-results-speaking',
            component: () =>
              import('@/modules/corporateManager/Students/pages/PaidTestResult/SpeakingView.vue')
          },
          {
            path: 'certificate',
            name: 'corporate-manager-student-paid-results-certificate',
            component: () =>
              import('@/modules/corporateManager/Students/pages/PaidTestResult/CertificateView.vue')
          }
        ]
      }
    ]
  },
  {
    path: '/corporate-manager/pretests',
    name: 'corporate-manager-pretests',
    component: () => import('@/modules/corporateManager/Pretests/List/pages/TestListView.vue'),
    meta: {
      space: 'corporate_hr'
    }
  },
  {
    path: '/corporate-manager/pretests/paid',
    name: 'corporate-manager-pretests-paid',
    component: () => import('@/modules/corporateManager/Pretests/Paid/pages/BaseLayout.vue'),
    meta: {
      space: 'corporate_hr'
    },
    redirect: { name: 'corporate-manager-pretests-paid-students' },
    children: [
      {
        path: 'students',
        name: 'corporate-manager-pretests-paid-students',
        component: () => import('@/modules/corporateManager/Pretests/Paid/pages/StudentsView.vue')
      }
    ]
  },
  {
    path: '/corporate-manager/pretests/students/:slug',
    name: 'corporate-manager-free-test-students',
    component: () =>
      import('@/modules/corporateManager/Pretests/Free/pages/FreeTestAssignView.vue'),
    meta: {
      space: 'corporate_hr'
    }
  },
  {
    path: '/corporate-manager/groups',
    name: 'corporate-manager-groups',
    component: () => import('@/modules/corporateManager/Groups/List/pages/GroupListView.vue'),
    meta: {
      space: 'corporate_hr'
    }
  },
  {
    path: '/corporate-manager/groups/:id',
    name: 'corporate-manager-group-general',
    meta: {
      space: 'corporate_hr'
    },
    component: () => import('@/modules/corporateManager/Groups/General/pages/GroupGeneralView.vue')
  },
  {
    path: '/corporate-manager/groups/:id/students',
    name: 'corporate-manager-group-students',
    meta: {
      space: 'corporate_hr'
    },
    component: () =>
      import('@/modules/corporateManager/Groups/Students/pages/GroupStudentsView.vue')
  },
  {
    path: '/corporate-manager/groups/:id/schedule',
    name: 'company-manager-group-schedule',
    meta: {
      space: 'corporate_hr'
    },
    component: () =>
      import('@/modules/corporateManager/Groups/Schedule/pages/GroupScheduleView.vue')
  },
  {
    path: '/corporate-manager/groups/:id/schedule/lesson/:lessonId',
    name: 'company-manager-lesson',
    meta: {
      space: 'corporate_hr'
    },
    component: () => import('@/modules/corporateManager/Groups/Classwork/pages/GroupLessonView.vue')
  },
  {
    path: '/corporate-manager/groups/:id/assigned-tasks',
    name: 'company-manager-group-lesson-tasks',
    meta: {
      space: 'corporate_hr'
    },
    component: () =>
      import('@/modules/corporateManager/Groups/AssignedTasks/pages/AssignedTasksListView.vue')
  },
  {
    path: '/corporate-manager/groups/:id/assigned-tasks/task/:taskId',
    name: 'company-manager-group-lesson-homework',
    meta: {
      space: 'corporate_hr'
    },
    component: () =>
      import('@/modules/corporateManager/Groups/AssignedTasks/pages/AssignedTaskStudentsView.vue')
  },
  {
    path: '/corporate-manager/groups/:id/assigned-tasks/test/:taskId',
    name: 'company-manager-group-lesson-test',
    meta: {
      space: 'corporate_hr'
    },
    component: () =>
      import('@/modules/corporateManager/Groups/AssignedTasks/pages/AssignedTestStudentsView.vue')
  },
  {
    path: '/corporate-manager/groups/:id/assigned-tasks/task/:taskId/:studentId',
    name: 'company-manager-group-lesson-student-homework',
    meta: {
      space: 'corporate_hr'
    },
    component: () =>
      import('@/modules/corporateManager/Groups/AssignedTasks/pages/AssignedTaskView.vue')
  },
  {
    path: '/corporate-manager/groups/:id/assigned-tasks/test/:taskId/:studentId',
    name: 'company-manager-group-lesson-student-test',
    meta: {
      space: 'corporate_hr'
    },
    component: () =>
      import('@/modules/corporateManager/Groups/AssignedTasks/pages/AssignedTestView.vue')
  },
  {
    path: '/corporate-manager/groups/:id/course-summary',
    name: 'corporate-manager-group-course-summary',
    meta: {
      space: 'corporate_hr'
    },
    component: () =>
      import('@/modules/corporateManager/Groups/CourseSummary/pages/GroupCourseSummaryView.vue')
  },
  {
    path: '/corporate-manager/groups/:id/feedback',
    name: 'corporate-manager-group-feedback',
    meta: {
      space: 'corporate_hr'
    },
    component: () =>
      import('@/modules/corporateManager/Groups/Feedback/pages/GroupFeedbackView.vue')
  },
  {
    path: '/corporate-manager/individuals',
    name: 'corporate-manager-individuals',
    meta: {
      space: 'corporate_hr'
    },
    component: () =>
      import('@/modules/corporateManager/Individuals/List/pages/IndividualsListView.vue')
  },
  {
    path: '/corporate-manager/individuals/:id',
    name: 'corporate-manager-individual-general',
    meta: {
      space: 'corporate_hr'
    },
    component: () =>
      import('@/modules/corporateManager/Individuals/General/pages/IndividualGeneralView.vue')
  },
  {
    path: '/corporate-manager/individuals/schedule/:id',
    name: 'corporate-manager-individual-schedule',
    meta: {
      space: 'corporate_hr'
    },
    component: () =>
      import('@/modules/corporateManager/Individuals/Schedule/pages/IndividualScheduleView.vue')
  },
  {
    path: '/corporate-manager/individuals/schedule/:id/lesson/:lessonId',
    name: 'corporate-manager-individual-lesson',
    meta: {
      space: 'corporate_hr'
    },
    component: () =>
      import('@/modules/corporateManager/Individuals/Classwork/pages/IndividualLessonView.vue')
  },
  {
    path: '/corporate-manager/statistics',
    name: 'corporate-manager-statistics',
    meta: {
      space: 'corporate_hr'
    },
    component: () => import('@/modules/corporateManager/Statistics/pages/ManagerStatisticsView.vue')
  }
]
