<template>
  <button @click="clickButton" class="layout-cabinet_header-menu-button" type="button">
    <vue-feather v-if="openMenu" type="x" stroke="#2B4DAB" size="24px"></vue-feather>
    <vue-feather v-else type="menu" stroke="#2B4DAB" size="24px"></vue-feather>
  </button>
</template>

<script lang="ts" setup>
defineProps({
  openMenu: Boolean
})
const emit = defineEmits(['click-button'])

const clickButton = () => {
  emit('click-button')
}
</script>
