export default [
  {
    path: '/profile',
    name: 'profile',
    component: () => import('@/modules/general/User/pages/ProfileView.vue')
  },
  {
    path: '/profile/certificates',
    name: 'profile-certificates',
    component: () => import('@/modules/general/User/pages/CertificatesView.vue')
  }
]
