<template>
  <div class="language-switcher">
    <button
      @click="setLocale('uk')"
      type="button"
      class="item"
      :class="{ selected: selected === 'uk' }"
      data-testid="language-switcher-uk"
    >
      <span class="p-body-semi-bold text-font-body" data-testid="language-switcher-uk-text">{{
        $t('language_switcher_uk')
      }}</span>
    </button>
    <button
      @click="setLocale('en')"
      type="button"
      class="item"
      :class="{ selected: selected === 'en' }"
      data-testid="language-switcher-en"
    >
      <span class="p-body-semi-bold text-font-body" data-testid="language-switcher-en-text">{{
        $t('language_switcher_en')
      }}</span>
    </button>
  </div>
</template>

<script lang="ts" setup>
import { useLanguageSwitcher } from '@/shared/composables/useLanguageSwitcher'

const { selected, setLocale } = useLanguageSwitcher()
</script>

<style lang="scss" scoped>
.language-switcher {
  display: flex;
  align-items: center;

  .item {
    padding: 6px 10px;
    cursor: pointer;
    user-select: none;

    span {
      padding: 4px 8px;
      border-radius: 4px;
      display: block;
      transition: all 0.3s ease-in-out;
    }

    &.selected,
    &:hover {
      span {
        background: $transparent-primary;
      }
    }
  }
}
</style>
