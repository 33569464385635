export default [
  {
    path: `/parent/groups/`,
    name: 'parent-groups',
    meta: {
      space: 'responsible_person'
    },
    component: () => import('@/modules/parent/Group/List/pages/GroupCoursesListView.vue')
  },
  {
    path: '/parent/groups/:id/:studentId',
    name: 'parent-groups-general',
    meta: {
      space: 'responsible_person'
    },
    component: () => import('@/modules/parent/Group/General/pages/GroupGeneralView.vue')
  },
  {
    path: '/parent/groups/schedule/:id/:studentId',
    name: 'parent-groups-schedule',
    meta: {
      space: 'responsible_person'
    },
    component: () => import('@/modules/parent/Group/Schedule/pages/GroupScheduleView.vue')
  },
  {
    path: '/parent/groups/schedule/:id/:studentId/lesson/:lessonId',
    name: 'parent-groups-lesson',
    meta: {
      space: 'responsible_person'
    },
    component: () => import('@/modules/parent/Group/Classwork/pages/GroupLessonView.vue')
  },
  {
    path: '/parent/groups/:id/:studentId/assigned-tasks',
    name: 'parent-groups-assigned-task',
    meta: {
      space: 'responsible_person'
    },
    component: () => import('@/modules/parent/Group/AssignedTasks/pages/GroupAssignedTasksView.vue')
  },
  {
    path: '/parent/groups/:id/:studentId/assigned-tasks/:taskId',
    name: 'parent-groups-assigned-task-item',
    meta: {
      space: 'responsible_person'
    },
    component: () => import('@/modules/parent/Group/AssignedTasks/pages/AssignedTaskView.vue')
  },
  {
    path: '/parent/groups/:id/:studentId/assigned-tasks/test/:taskId',
    name: 'parent-groups-assigned-task-test',
    meta: {
      space: 'responsible_person'
    },
    component: () => import('@/modules/parent/Group/AssignedTasks/pages/AssignedTestView.vue')
  },
  {
    path: `/parent/groups/feedbacks/:id/:studentId`,
    name: 'parent-groups-feedbacks',
    meta: {
      space: 'responsible_person'
    },
    component: () => import('@/modules/parent/Group/Feedbacks/pages/GroupsFeedbacksView.vue')
  },
  {
    path: '/parent/groups/summary/:id/:studentId',
    name: 'parent-groups-summary',
    meta: {
      space: 'responsible_person'
    },
    component: () => import('@/modules/parent/Group/CourseSummary/pages/GroupSummaryView.vue')
  },
  {
    path: '/parent/individuals',
    name: 'parent-individuals',
    meta: {
      space: 'responsible_person'
    },
    component: () => import('@/modules/parent/Individuals/List/pages/ParentIndividualsView.vue')
  },
  {
    path: '/parent/individuals/general/:id',
    name: 'parent-individuals-general',
    meta: {
      space: 'responsible_person'
    },
    component: () =>
      import('@/modules/parent/Individuals/General/pages/ParentIndividualsGeneralView.vue')
  },
  {
    path: '/parent/individuals/schedule/:id',
    name: 'parent-individuals-schedule',
    meta: {
      space: 'responsible_person'
    },
    component: () =>
      import('@/modules/parent/Individuals/Schedule/pages/ParentIndividualsScheduleView.vue')
  },
  {
    path: '/parent/individuals/schedule/:id/lesson/:lessonId',
    name: 'parent-individuals-lesson',
    meta: {
      space: 'responsible_person'
    },
    component: () => import('@/modules/parent/Individuals/Classwork/pages/IndividualLessonView.vue')
  },
  {
    path: '/parent/clubs',
    name: 'parent-clubs-general',
    meta: {
      space: 'responsible_person'
    },
    component: () => import('@/modules/parent/Clubs/pages/ChildrenClubsView.vue')
  }
]
