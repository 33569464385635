import authorisations from './authorisations'
import checkout from './checkout'
import corporateManager from './corporateManager'
import corporateStudent from './corporateStudent'
import corporateTeacher from './corporateTeacher'
import parent from './parent'
import partners from './partners'
import pretests from './pretests'
import student from './student'
import studentTeacherTraining from './student-teacher-training.ts'
import teacher from './teacher'
import tutor from './tutor'
import user from './user'

export const routes = [
  ...user,
  ...authorisations,
  ...checkout,
  ...student,
  ...studentTeacherTraining,
  ...teacher,
  ...parent,
  ...partners,
  ...pretests,
  ...corporateStudent,
  ...corporateTeacher,
  ...corporateManager,
  ...tutor,
  {
    path: '/',
    redirect: '/profile'
  },
  {
    path: '/support',
    name: 'support',
    component: () => import(`@/modules/general/Support/pages/SupportView.vue`)
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/modules/general/Layout/pages/Error404View.vue'),
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: 'error-404'
  }
]
