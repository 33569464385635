<template>
  <div class="app">
    <component :is="layout">
      <RouterView :key="routeParamsString" />
    </component>
  </div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import { computed } from 'vue'
import { RouterView } from 'vue-router'
import { useRoute } from 'vue-router'
import { LayoutCabinet, LayoutDefault } from '@/modules/general/Layout/widgets/ui'
import { useWebsockets } from '@/shared/composables/useWebsockets'

const route = useRoute()

const routeParamsString = computed(() => {
  return JSON.stringify(route.params)
})

const layout = computed(() => {
  if (route.meta.layout === 'default') {
    return LayoutDefault
  }
  return LayoutCabinet
})

const ws = useWebsockets()

onMounted(() => {
  if (localStorage.getItem('token')) {
    ws.init()
  }
})
</script>
