<template>
  <div class="layout-cabinet_footer">
    <div class="row">
      <div class="col-12">
        <p class="p-body-small py-16 cab-border-top text-center text-font-heading-display">
          © Grade Cabinet {{ new Date().getFullYear() }}, by Grade Education Centre
        </p>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts"></script>
