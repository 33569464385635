export function useBodyScrollLock() {
  const bodyElement: HTMLBodyElement | null = document.querySelector('body')

  function lockScroll() {
    if (bodyElement) {
      bodyElement.style.overflow = 'hidden'
    }
  }

  function unlockScroll() {
    if (bodyElement) {
      bodyElement.style.overflow = 'auto'
    }
  }

  return {
    lockScroll,
    unlockScroll
  }
}
