export default [
  {
    path: '/tutor',
    name: 'tutor-space',
    meta: {
      space: 'tutor'
    },
    component: () => import('@/entities/Layout/ui/SuspenseComponent.vue'),
    redirect: { name: 'tutor-tkt-groups' },
    children: [
      {
        path: 'tkt-groups',
        name: 'tutor-tkt-groups',
        component: () => import('@/modules/tutor/TKTGroups/List/pages/CardsListView.vue')
      },
      {
        path: 'tkt-groups/:id',
        name: 'tutor-tkt-group',
        component: () => import('@/modules/tutor/TKTGroups/Layout/widgets/ui/GroupLayout.vue'),
        redirect: { name: 'tutor-tkt-group-general' },
        children: [
          {
            path: 'general',
            name: 'tutor-tkt-group-general',
            component: () => import('@/modules/tutor/TKTGroups/General/pages/GeneralView.vue')
          },
          {
            path: 'schedule',
            name: 'tutor-tkt-group-schedule',
            component: () =>
              import('@/modules/tutor/TKTGroups/Schedule/List/pages/ScheduleView.vue')
          },
          {
            path: 'schedule/:lessonId',
            name: 'tutor-tkt-group-schedule-lesson',
            component: () =>
              import('@/modules/tutor/TKTGroups/Schedule/Layout/widgets/ui/LessonLayout.vue'),
            redirect: { name: 'tutor-tkt-group-schedule-lesson-summary' },
            children: [
              {
                path: 'lesson-summary',
                name: 'tutor-tkt-group-schedule-lesson-summary',
                component: () =>
                  import('@/modules/tutor/TKTGroups/Schedule/Lesson/pages/LessonSummaryView.vue')
              },
              {
                path: 'lesson-notes',
                name: 'tutor-tkt-group-schedule-lesson-notes',
                component: () =>
                  import('@/modules/tutor/TKTGroups/Schedule/Lesson/pages/LessonNotesView.vue')
              },
              {
                path: 'lesson-meeting',
                name: 'tutor-tkt-group-schedule-lesson-meeting',
                component: () =>
                  import('@/modules/tutor/TKTGroups/Schedule/Lesson/pages/LessonMeetingView.vue')
              },
              {
                path: 'lesson-substitution',
                name: 'tutor-tkt-group-schedule-lesson-substitution',
                component: () =>
                  import(
                    '@/modules/tutor/TKTGroups/Schedule/Lesson/pages/LessonSubstitutionView.vue'
                  )
              }
            ]
          },
          {
            path: 'trainees',
            name: 'tutor-tkt-group-trainees',
            component: () => import('@/modules/tutor/TKTGroups/Trainees/pages/TraineesView.vue')
          },
          {
            path: 'course-summary',
            name: 'tutor-tkt-group-course-summary',
            component: () =>
              import('@/modules/tutor/TKTGroups/CourseSummary/pages/CourseSummaryView.vue')
          }
        ]
      },
      {
        path: 'trainees',
        name: 'tutor-trainees',
        component: () => import('@/modules/tutor/Trainees/pages/TraineesListView.vue')
      },
      {
        path: 'substitutions/:type?',
        name: 'tutor-substitutions',
        component: () =>
          import('@/modules/tutor/Substitutions/List/pages/SubstitutionsListView.vue')
      },
      {
        path: 'tkt-groups/substitutions/:id',
        name: 'tutor-substitution-group',
        component: () => import('@/modules/tutor/Substitutions/Layout/widgets/ui/GroupLayout.vue'),
        redirect: { name: 'tutor-substitution-group-general' },
        children: [
          {
            path: 'general',
            name: 'tutor-substitution-group-general',
            component: () =>
              import('@/modules/tutor/Substitutions/General/pages/SubstitutionGeneralView.vue')
          },
          {
            path: 'schedule',
            name: 'tutor-substitution-group-schedule',
            component: () =>
              import(
                '@/modules/tutor/Substitutions/Schedule/List/pages/SubstitutionScheduleView.vue'
              )
          },
          {
            path: 'schedule/lesson/:lessonId',
            name: 'tutor-substitution-group-schedule-lesson',
            component: () =>
              import('@/modules/tutor/Substitutions/Schedule/Layout/widgets/ui/LessonLayout.vue'),
            redirect: { name: 'tutor-substitution-group-lesson-summary' },
            children: [
              {
                path: 'lesson-summary',
                name: 'tutor-substitution-group-lesson-summary',
                component: () =>
                  import(
                    '@/modules/tutor/Substitutions/Schedule/Lesson/pages/LessonSummaryView.vue'
                  )
              },
              {
                path: 'lesson-notes',
                name: 'tutor-substitution-group-lesson-notes',
                component: () =>
                  import('@/modules/tutor/Substitutions/Schedule/Lesson/pages/LessonNotesView.vue')
              },
              {
                path: 'lesson-meeting',
                name: 'tutor-substitution-group-lesson-meeting',
                component: () =>
                  import(
                    '@/modules/tutor/Substitutions/Schedule/Lesson/pages/LessonMeetingView.vue'
                  )
              },
              {
                path: 'lesson-substitution',
                name: 'tutor-substitution-group-lesson-substitution',
                component: () =>
                  import(
                    '@/modules/tutor/Substitutions/Schedule/Lesson/pages/LessonSubstitutionView.vue'
                  )
              }
            ]
          },
          {
            path: 'trainees',
            name: 'tutor-substitution-group-trainees',
            component: () =>
              import('@/modules/tutor/Substitutions/Trainees/pages/SubstitutionTraineesView.vue')
          }
        ]
      }
    ]
  }
]
