import { useAuthStore } from '@/entities/User/model'

export default [
  {
    path: '/login',
    name: 'login',
    component: () => import('@/modules/general/User/pages/LoginView.vue'),
    meta: {
      layout: 'default'
    },
    beforeEnter: () => {
      const auth = useAuthStore()
      if (auth.loggedIn) {
        return '/profile'
      }
    }
  },
  {
    path: '/login-as',
    name: 'login-as',
    component: () => import('@/modules/general/User/pages/LoginAsView.vue'),
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/login-google',
    name: 'login-google',
    component: () => import('@/modules/general/User/pages/LoginGoogleView.vue'),
    meta: {
      layout: 'default'
    }
  },
  {
    path: '/login/success',
    name: 'login-success',
    component: () => import('@/modules/general/User/pages/LoginSuccessView.vue'),
    meta: {
      layout: 'default'
    }
  }
]
