export type User = {
  email: string
  name: string
  name_en: string
  id: number
  spaces: string[]
  callback: {
    type: string | null
    email: string | null
    phone: string | null
  }
  phone?: string
  address?: string
  gender?: string
  birthday?: string
  avatar?: {
    data: {
      id: number
      name: string
      path: string
      type: string
    }
  }
  teacher_id?: number
  company_name?: string
}

export type UserCertificate = {
  certificate: string
  certificate_uk: string | null
  certificate_code: string
}

export type UserCertificatePagination = {
  total: number
  per_page: number
  current_page: number
  total_pages: number
}

export type UserResponse = {
  data: {
    data: User
  }
}

export type LoginUserResponse = {
  data: {
    token: string
    user: {
      data: User
    }
  }
}

export type UserCertificatesResponse = {
  data: {
    data: UserCertificate[]
    meta: {
      pagination: UserCertificatePagination
    }
  }
}

export enum UserSpace {
  Tutor = 'tutor',
  Student = 'student',
  Teacher = 'teacher',
  Parent = 'responsible_person',
  CorporateStudent = 'corporate_student',
  CorporateHr = 'corporate_hr',
  CorporateTeacher = 'corporate_teacher',
  StudentTeacherTraining = 'student_teacher_training',
  Dos = 'dos'
}

export type ContactType = keyof typeof contactInputName | keyof typeof contactAutocompleteType | ''

export enum contactInputName {
  email = 'email',
  phone = 'phone',
  viber = 'phone',
  telegram = 'text',
  nothing = 'nothing'
}

export enum contactAutocompleteType {
  email = 'email',
  phone = 'tel',
  viber = 'tel',
  telegram = 'text',
  nothing = 'text'
}
