import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useEmailValid } from '@/shared/composables/useEmailValid'
import { useWebsockets } from '@/shared/composables/useWebsockets'
import { registerAuthUser, loginAuthUser, loginUserWithGoogle, resetUserPassword } from '../../api'
import { useAuthStore } from '../../model'
import { UserSpace } from '../../types'

const ws = useWebsockets()

export function useLoginUser() {
  const router = useRouter()
  const { t } = useI18n()
  const authStore = useAuthStore()
  const { email, emailError, validateEmail, focusEmailInput } = useEmailValid()
  const password = ref('')
  const resendPassword = ref(false)
  const existingEmail = ref(false)
  const submitted = ref(false)
  const loading = ref(false)
  const passwordError = ref('')

  function registerUser() {
    validateEmail(() => {
      loading.value = true

      registerAuthUser(email.value)
        .then(() => {
          existingEmail.value = false
          submitted.value = true
          loading.value = false
          ws.init()
        })
        .catch((error) => {
          if (error.error === 'user_already_exist') {
            existingEmail.value = true
            submitted.value = true
          }
          loading.value = false
        })
    })
  }

  async function loginUser() {
    passwordError.value = ''
    loading.value = true

    await loginAuthUser(email.value, password.value)
      .then((response) => {
        authStore.setUser(response)
        loading.value = false

        if (authStore.returnUrl.path) {
          router
            .push(authStore.returnUrl)
            .then(() => localStorage.setItem('returnUrl', JSON.stringify({})))
        } else {
          if (existingEmail.value) {
            authStore.user && authStore.user.spaces.includes(UserSpace.Teacher)
              ? router.push({ name: 'teacher-calendar' })
              : router.push({ name: 'profile' })
          } else {
            router.push({ name: 'login-success' })
          }
        }

        ws.init()
      })
      .catch(() => {
        loading.value = false
        passwordError.value = t('form.incorrect_password')
      })
  }

  async function loginWithGoogleUser(token: string | null) {
    await loginUserWithGoogle(token).then((user) => {
      authStore.setUser(user)
      if (authStore.returnUrl.path) {
        router
          .push(authStore.returnUrl)
          .then(() => localStorage.setItem('returnUrl', JSON.stringify({})))
      } else {
        authStore.user && authStore.user.spaces.includes(UserSpace.Teacher)
          ? router.push({ name: 'teacher-calendar' })
          : router.push({ name: 'profile' })
      }
      ws.init()
    })
  }

  function resetPassword() {
    loading.value = true
    resetUserPassword(email.value).then(() => {
      passwordError.value = ''
      loading.value = false
      resendPassword.value = true
    })
  }

  function focusPasswordInput() {
    passwordError.value = ''
  }

  return {
    submitted,
    loading,
    password,
    email,
    emailError,
    existingEmail,
    resendPassword,
    passwordError,
    registerUser,
    loginUser,
    resetPassword,
    focusEmailInput,
    focusPasswordInput,
    loginWithGoogleUser
  }
}
