import { useAuthStore } from '@/entities/User/model'
import { UserSpace } from '@/entities/User/types'

export default [
  {
    path: '/pretests',
    name: 'pretest-general',
    component: () => import('@/modules/pretests/pages/TestListView.vue'),
    meta: {
      space: 'pretest'
    },
    beforeEnter: (to: any, from: any, next: any) => {
      const auth = useAuthStore()
      if (
        auth.user?.spaces.includes(UserSpace.CorporateStudent) ||
        auth.user?.spaces.includes(UserSpace.CorporateHr)
      ) {
        return next({ path: '/profile' })
      }
      return next()
    }
  },
  /**
   * All routes below are using for general & corporate users.
   */
  {
    path: '/pretests/:slug',
    name: 'pretest-intro',
    component: () => import('@/modules/pretests/pages/TestIntroView.vue'),
    meta: {
      space: 'pretest'
    }
  },
  {
    path: '/pretests/:slug/:testVariant/:testPart/start',
    name: 'pretest-section',
    component: () => import('@/modules/pretests/pages/TestSectionView.vue'),
    meta: {
      space: 'pretest'
    },
    beforeEnter: (to: any, from: any, next: any) => {
      if (
        (from.name === 'pretest-writing-test' && to.params.testPart === 'writing') ||
        (from.name === 'pretest-reading-test' && to.params.testPart === 'reading') ||
        (from.name === 'pretest-grammar-test' && to.params.testPart === 'grammar')
      ) {
        return next({ name: 'pretest-general' })
      }
      return next()
    }
  },
  {
    path: '/pretests/:slug/:testVariant/grammar/test',
    name: 'pretest-grammar-test',
    component: () => import('@/modules/pretests/pages/LexisGrammarTasksView.vue'),
    meta: {
      space: 'pretest'
    },
    beforeEnter: (to: any, from: any, next: any) => {
      if (
        from.name === 'pretest-section' &&
        (from.params.testPart === 'reading' || from.params.testPart === 'writing')
      ) {
        return next({ name: 'pretest-general' })
      }
      return next()
    }
  },
  {
    path: '/pretests/:slug/:testVariant/writing/test',
    name: 'pretest-writing-test',
    component: () => import('@/modules/pretests/pages/WritingTaskView.vue'),
    meta: {
      space: 'pretest'
    },
    beforeEnter: (to: any, from: any, next: any) => {
      if (from.name === 'pretest-result') {
        return next({ name: 'pretest-general' })
      }
      return next()
    }
  },

  {
    path: '/pretests/:userTestVariantId/result',
    component: () => import('@/modules/pretests/pages/TestResultView.vue'),
    meta: {
      space: 'pretest'
    },
    children: [
      {
        path: 'general',
        name: 'pretest-result',
        component: () => import('@/modules/pretests/widgets/TestResult/ui/GeneralResult.vue')
      },
      {
        path: 'grammar',
        name: 'pretest-result-grammar',
        component: () => import('@/modules/pretests/widgets/TestResult/ui/GrammarResult.vue')
      },
      {
        path: 'writing',
        name: 'pretest-result-writing',
        component: () => import('@/modules/pretests/widgets/TestResult/ui/WritingResult.vue')
      },
      {
        path: 'reading',
        name: 'pretest-result-reading',
        component: () => import('@/modules/pretests/widgets/TestResult/ui/ReadingResult.vue')
      },
      {
        path: 'speaking',
        name: 'pretest-result-speaking',
        component: () => import('@/modules/pretests/widgets/TestResult/ui/SpeakingResult.vue')
      }
    ]
  },
  {
    path: '/pretests/:slug/:testVariant/reading/test',
    name: 'pretest-reading-test',
    component: () => import('@/modules/pretests/pages/ReadingTaskView.vue'),
    meta: {
      space: 'pretest'
    },
    beforeEnter: (to: any, from: any, next: any) => {
      if (from.name === 'pretest-section' && from.params.testPart === 'writing') {
        return next({ name: 'pretest-general' })
      }
      return next()
    }
  }
]
