export default [
  {
    path: '/student/clubs/upcoming',
    name: 'student-clubs',
    meta: {
      space: 'student'
    },
    component: () => import('@/modules/student/Club/pages/ClubsListView.vue')
  },
  {
    path: '/student/clubs/archived',
    name: 'student-clubs-archived',
    meta: {
      space: 'student'
    },
    component: () => import('@/modules/student/Club/pages/ClubsListView.vue')
  },
  {
    path: `/student/groups/`,
    name: 'student-groups',
    meta: {
      space: 'student'
    },
    component: () => import('@/modules/student/Group/List/pages/GroupCoursesListView.vue')
  },
  {
    path: '/student/groups/:id',
    name: 'student-groups-general',
    meta: {
      space: 'student'
    },
    component: () => import('@/modules/student/Group/General/pages/GroupGeneralView.vue')
  },
  {
    path: '/student/groups/schedule/:id',
    name: 'student-groups-schedule',
    meta: {
      space: 'student'
    },
    component: () => import('@/modules/student/Group/Schedule/pages/GroupScheduleView.vue')
  },
  {
    path: '/student/groups/schedule/:id/lesson/:lessonId',
    name: 'student-groups-schedule-lesson',
    meta: {
      space: 'student'
    },
    component: () => import('@/modules/student/Group/Classwork/pages/GroupScheduleLessonView.vue')
  },
  {
    path: '/student/groups/summary/:id',
    name: 'student-groups-summary',
    meta: {
      space: 'student'
    },
    component: () => import('@/modules/student/Group/CourseSummary/pages/GroupSummaryView.vue')
  },
  {
    path: '/student/groups/:id/assigned-tasks',
    name: 'student-groups-assigned-task',
    meta: {
      space: 'student'
    },
    component: () =>
      import('@/modules/student/Group/AssignedTasks/pages/GroupAssignedTasksView.vue')
  },
  {
    path: '/student/groups/:id/assigned-tasks/:taskId',
    name: 'student-groups-assigned-task-item',
    meta: {
      space: 'student'
    },
    component: () => import('@/modules/student/Group/AssignedTasks/pages/AssignedTaskView.vue')
  },
  {
    path: '/student/groups/:id/assigned-tasks/test/:taskId',
    name: 'student-groups-assigned-task-test',
    meta: {
      space: 'student'
    },
    component: () => import('@/modules/student/Group/AssignedTasks/pages/AssignedTestView.vue')
  },
  {
    path: '/student/groups/:id/catch-up',
    name: 'student-groups-catch-up',
    meta: {
      space: 'student'
    },
    component: () => import('@/modules/student/Group/CatchUp/pages/GroupCatchUpView.vue')
  },
  {
    path: `/student/individuals/`,
    name: 'student-individuals',
    meta: {
      space: 'student'
    },
    component: () => import('@/modules/student/Individuals/List/pages/IndividualsListView.vue')
  },
  {
    path: `/student/individuals/:id`,
    name: 'student-individuals-general',
    meta: {
      space: 'student'
    },
    component: () => import('@/modules/student/Individuals/General/pages/IndividualGeneralView.vue')
  },
  {
    path: `/student/individuals/schedule/:id`,
    name: 'student-individuals-schedule',
    meta: {
      space: 'student'
    },
    component: () =>
      import('@/modules/student/Individuals/Schedule/pages/IndividualScheduleView.vue')
  },
  {
    path: '/student/individuals/schedule/:id/lesson/:lessonId',
    name: 'student-individuals-lesson',
    meta: {
      space: 'student'
    },
    component: () =>
      import('@/modules/student/Individuals/Classwork/pages/IndividualLessonView.vue')
  }
]
