export default [
  {
    path: '/teacher-corporate/groups',
    name: 'company-teacher-groups',
    meta: {
      space: 'corporate_teacher'
    },
    component: () => import('@/modules/corporateTeacher/Groups/List/pages/GroupCoursesListView.vue')
  },
  {
    path: '/teacher-corporate/groups/:id',
    name: 'company-teacher-base-info-group',
    meta: {
      space: 'corporate_teacher'
    },
    component: () => import('@/modules/corporateTeacher/Groups/General/pages/GroupGeneralView.vue')
  },
  {
    path: '/teacher-corporate/groups/:id/schedule',
    name: 'company-teacher-groups-schedule',
    meta: {
      space: 'corporate_teacher'
    },
    component: () =>
      import('@/modules/corporateTeacher/Groups/Schedule/pages/GroupScheduleView.vue')
  },
  {
    path: '/teacher-corporate/groups/:id/schedule/lesson/:lessonId',
    name: 'company-teacher-groups-schedule-lesson',
    meta: {
      space: 'corporate_teacher'
    },
    component: () =>
      import('@/modules/corporateTeacher/Groups/Classwork/pages/GroupClassworkView.vue'),
    children: [
      {
        name: 'company-teacher-groups-schedule-lesson-summary',
        path: 'lesson-summary',
        component: () =>
          import('@/modules/corporateTeacher/Groups/Classwork/pages/LessonSummary.vue')
      },
      {
        name: 'company-teacher-groups-schedule-lesson-notes',
        path: 'notes',
        component: () => import('@/modules/corporateTeacher/Groups/Classwork/pages/Notes.vue')
      },
      {
        name: 'company-teacher-groups-schedule-lesson-zoom-info',
        path: 'zoom-info',
        component: () => import('@/modules/corporateTeacher/Groups/Classwork/pages/ZoomInfo.vue')
      },
      {
        name: 'company-teacher-groups-schedule-lesson-substitution',
        path: 'substitution',
        component: () =>
          import('@/modules/corporateTeacher/Groups/Classwork/pages/Substitution.vue')
      }
    ]
  },
  {
    path: '/teacher-corporate/groups/:id/assigned-tasks',
    name: 'company-teacher-group-tasks',
    meta: {
      space: 'corporate_teacher'
    },
    component: () =>
      import('@/modules/corporateTeacher/Groups/AssignedTasks/pages/AssignedTasksListView.vue')
  },
  {
    path: '/teacher-corporate/groups/:id/assigned-tasks/task/:taskId',
    name: 'company-teacher-group-homework',
    meta: {
      space: 'corporate_teacher'
    },
    component: () =>
      import('@/modules/corporateTeacher/Groups/AssignedTasks/pages/AssignedTaskStudentsView.vue')
  },
  {
    path: '/teacher-corporate/groups/:id/assigned-tasks/test/:taskId',
    name: 'company-teacher-test-student-list',
    meta: {
      space: 'corporate_teacher'
    },
    component: () =>
      import('@/modules/corporateTeacher/Groups/AssignedTasks/pages/AssignedTestStudentsView.vue')
  },
  {
    path: '/teacher-corporate/groups/:id/assigned-tasks/task/:taskId/:studentId',
    name: 'company-student-homework',
    meta: {
      space: 'corporate_teacher'
    },
    component: () =>
      import('@/modules/corporateTeacher/Groups/AssignedTasks/pages/AssignedTaskView.vue')
  },
  {
    path: '/teacher-corporate/groups/:id/assigned-tasks/test/:taskId/:studentId',
    name: 'company-teacher-student-test-view',
    meta: {
      space: 'corporate_teacher'
    },
    component: () =>
      import('@/modules/corporateTeacher/Groups/AssignedTasks/pages/AssignedTestView.vue')
  },
  {
    path: '/teacher-corporate/groups/:id/assigned-tasks/edit-test/:taskId',
    name: 'company-teacher-test-info',
    meta: {
      space: 'corporate_teacher'
    },
    component: () =>
      import('@/modules/corporateTeacher/Groups/AssignedTasks/pages/AssignedGeneralTestView.vue')
  },
  {
    path: '/teacher-corporate/groups/:id/students',
    name: 'corporate-teacher-groups-students',
    meta: {
      space: 'corporate_teacher'
    },
    component: () =>
      import('@/modules/corporateTeacher/Groups/Students/pages/GroupStudentsView.vue')
  },
  {
    path: '/teacher-corporate/groups/:id/course-summary',
    name: 'corporate-teacher-groups-course-summary',
    meta: {
      space: 'corporate_teacher'
    },
    component: () =>
      import('@/modules/corporateTeacher/Groups/CourseSummary/pages/GroupCourseSummaryView.vue')
  },
  {
    path: '/teacher-corporate/groups/:id/feedback',
    meta: {
      space: 'corporate_teacher'
    },
    component: () =>
      import('@/modules/corporateTeacher/Groups/Feedback/pages/GroupFeedbackView.vue'),
    children: [
      {
        path: '',
        name: 'corporate-teacher-groups-feedback',
        component: () =>
          import('@/modules/corporateTeacher/Groups/Feedback/pages/GroupFeedbackEditView.vue')
      },
      {
        path: ':feedbackId/preview',
        name: 'corporate-teacher-groups-feedback-preview',
        component: () =>
          import('@/modules/corporateTeacher/Groups/Feedback/pages/GroupFeedbackPreviewView.vue')
      }
    ]
  },
  {
    path: '/teacher-corporate/individuals',
    name: 'corporate-teacher-individuals',
    meta: {
      space: 'corporate_teacher'
    },
    component: () =>
      import('@/modules/corporateTeacher/Individuals/List/pages/IndividualsListView.vue')
  },
  {
    path: '/teacher-corporate/individuals/general/:id',
    name: 'corporate-teacher-individuals-general',
    meta: {
      space: 'teacher'
    },
    component: () =>
      import('@/modules/corporateTeacher/Individuals/General/pages/IndividualsGeneralView.vue')
  },
  {
    path: '/teacher-corporate/individuals/schedule/:id',
    name: 'corporate-teacher-individuals-schedule',
    meta: {
      space: 'corporate_teacher'
    },
    component: () =>
      import('@/modules/corporateTeacher/Individuals/Schedule/pages/IndividualsScheduleView.vue')
  },
  {
    path: '/teacher-corporate/individuals/schedule/:id/lesson/:lessonId',
    name: 'corporate-teacher-individuals-schedule-lesson',
    meta: {
      space: 'corporate_teacher'
    },
    component: () =>
      import('@/modules/corporateTeacher/Individuals/Classwork/pages/IndividualsClassworkView.vue'),
    children: [
      {
        name: 'corporate-teacher-individuals-schedule-lesson-summary',
        path: 'lesson-summary',
        component: () =>
          import('@/modules/corporateTeacher/Individuals/Classwork/pages/LessonSummary.vue')
      },
      {
        name: 'corporate-teacher-individuals-schedule-lesson-notes',
        path: 'notes',
        component: () => import('@/modules/corporateTeacher/Individuals/Classwork/pages/Notes.vue')
      },
      {
        name: 'corporate-teacher-individuals-schedule-lesson-substitution',
        path: 'substitution',
        component: () =>
          import('@/modules/corporateTeacher/Individuals/Classwork/pages/Substitution.vue')
      }
    ]
  },
  {
    path: '/teacher-corporate/students',
    name: 'corporate-teacher-students-list',
    meta: {
      space: 'corporate_teacher'
    },
    component: () => import('@/modules/corporateTeacher/Students/pages/StudentListView.vue')
  },
  {
    path: '/teacher-corporate/students/:id',
    name: 'corporate-teacher-student',
    meta: {
      space: 'corporate_teacher'
    },
    component: () => import('@/modules/corporateTeacher/Students/pages/StudentView.vue')
  },
  {
    path: '/teacher-corporate/pretests',
    name: 'corporate-teacher-pretests',
    meta: {
      space: 'corporate_teacher'
    },
    component: () => import('@/modules/corporateTeacher/Pretests/Paid/pages/BaseLayout.vue'),
    children: [
      {
        path: 'paid',
        name: 'corporate-teacher-pretests-paid',
        component: () =>
          import('@/modules/corporateTeacher/Pretests/Paid/pages/TestsCardsListView.vue')
      },
      {
        path: 'paid/:id',
        name: 'corporate-teacher-pretests-paid-id',
        component: () => import('@/modules/corporateTeacher/Pretests/Paid/pages/TestLayout.vue'),
        children: [
          {
            path: 'writing',
            name: 'corporate-teacher-pretests-paid-writing',
            component: () =>
              import('@/modules/corporateTeacher/Pretests/Paid/pages/WritingStudentsView.vue')
          },
          {
            path: 'speaking',
            name: 'corporate-teacher-pretests-paid-speaking',
            component: () =>
              import('@/modules/corporateTeacher/Pretests/Paid/pages/SpeakingStudentsView.vue')
          },
          {
            path: 'writing/feedback/:feedbackId',
            name: 'corporate-teacher-pretests-paid-writing-feedback',
            component: () =>
              import('@/modules/corporateTeacher/Pretests/Paid/pages/WritingFeedbackView.vue')
          },
          {
            path: 'speaking/feedback/:feedbackId',
            name: 'corporate-teacher-pretests-paid-speaking-feedback',
            component: () =>
              import('@/modules/corporateTeacher/Pretests/Paid/pages/SpeakingFeedbackView.vue')
          }
        ]
      }
    ]
  }
]
