import axios from 'axios'
import { useToast } from 'vue-toastification'
import { refreshToken } from './api'
import type { HttpRequestOptions } from './types'

const toast = useToast()

export const instance = axios.create({
  baseURL: import.meta.env.VITE_API_URL
})

instance.interceptors.request.use(
  (config) => {
    // Get token from localStorage
    const token = localStorage.getItem('token')

    if (token) {
      // @ts-ignore
      config.headers.Authorization = 'Bearer ' + token
    }
    return config
  },
  (error) => Promise.reject(error)
)

instance.interceptors.response.use(
  (res) => {
    return res
  },
  async (err) => {
    const originalConfig = err.config
    if (err.response && originalConfig.url !== 'api/v1/cabinet-auth') {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true

        try {
          await refreshToken()

          return instance(originalConfig)
        } catch (_error) {
          return Promise.reject(_error)
        }
      }
    }

    return Promise.reject(err)
  }
)

export const request = <T = void>(
  options: HttpRequestOptions,
  settings: { isMock?: boolean; showErrorToast?: boolean } = { isMock: false, showErrorToast: true }
): Promise<T> => {
  // @ts-ignore
  return instance
    .request({
      baseURL:
        settings?.isMock && import.meta.env.VITE_NODE_ENV === 'dev'
          ? import.meta.env.VITE_MOCK_API_URL
          : import.meta.env.VITE_API_URL,
      url: options.url,
      method: options.method,
      data: options?.data,
      params: options?.params
    })
    .then((response) => response)
    .catch((error) => {
      // Show error message to user
      if (settings.showErrorToast) {
        toast.error(
          error.response?.data ? error.response.data.message : 'An unknown error occurred'
        )
      }

      // Throw error to catch it in the component if needed
      throw error.response?.data
    })
}
