// Usage inside a component:
//
// <p>{{ $t('language_switcher') }}</p>
//
// import { useI18n } from 'vue-i18n'
// const language = useI18n()

import messages from '@intlify/unplugin-vue-i18n/messages'
import { createI18n } from 'vue-i18n'

export const i18n = createI18n({
  legacy: false,
  globalInjection: true,
  locale: localStorage.getItem('appLanguage') || 'uk',
  fallbackLocale: 'en',
  availableLocales: ['en', 'uk'],
  messages: messages
})
