<template>
  <div class="layout-cabinet_header-logos">
    <div class="logos layout-cabinet_header-logos-wrap d-flex align-items-center">
      <a
        href="https://cambridge.ua/uk/"
        target="_blank"
        class="d-block layout-cabinet_header-logo-wrap"
      >
        <img class="cambridge-logo" src="@/shared/images/logo/CambridgeColor.svg" alt="" />
      </a>

      <a href="https://grade.ua/uk/" target="_blank">
        <img class="grade-logo" src="@/shared/images/logo/GradeColor.svg" alt="" />
      </a>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style lang="scss" scoped>
.layout-cabinet_header {
  &-logos-wrap {
    padding-bottom: 20px;
    margin-left: 0;

    @include lg() {
      margin-left: 20px;
      padding-bottom: 0 !important;
    }
  }

  &-logo-wrap {
    margin-right: 20px;

    @include lg() {
      margin-right: 10px;
    }
  }
}
</style>
