export default [
  {
    path: '/corporate/pretests',
    name: 'corporate-pretest-general',
    component: () =>
      import('@/modules/corporateStudent/Pretests/List/pages/TestInfoCardListView.vue'),
    meta: {
      space: 'corporate_student'
    }
  },
  {
    path: '/corporate/pretests/paid',
    component: () => import('@/modules/corporateStudent/Pretests/Paid/pages/BaseLayout.vue'),
    meta: {
      space: 'corporate_student'
    },
    redirect: { name: 'corporate-pretest-info' },
    children: [
      {
        path: 'list',
        name: 'corporate-pretest-info',
        component: () =>
          import('@/modules/corporateStudent/Pretests/Paid/pages/TestPartsListView.vue'),
        meta: {
          space: 'corporate_student'
        }
      },
      {
        path: 'grammar/start',
        name: 'corporate-pretest-section-grammar-start',
        component: () =>
          import('@/modules/corporateStudent/Pretests/Paid/pages/LexisGrammarStartSectionView.vue'),
        meta: {
          space: 'pretest'
        },
        beforeEnter: (to: any, from: any, next: any) => {
          if (from.name === 'corporate-pretest-section-grammar-test') {
            return next({ name: 'corporate-pretest-general' })
          }
          return next()
        }
      },
      {
        path: 'paid/grammar',
        name: 'corporate-pretest-section-grammar-test',
        component: () =>
          import('@/modules/corporateStudent/Pretests/Paid/pages/LexisGrammarTasksView.vue'),
        meta: {
          space: 'corporate_student'
        }
      },
      {
        path: 'writing/start',
        name: 'corporate-pretest-section-writing-start',
        component: () =>
          import('@/modules/corporateStudent/Pretests/Paid/pages/WritingStartSectionView.vue'),
        meta: {
          space: 'pretest'
        },
        beforeEnter: (to: any, from: any, next: any) => {
          if (from.name === 'corporate-pretest-section-writing-test') {
            return next({ name: 'corporate-pretest-general' })
          }
          return next()
        }
      },
      {
        path: 'writing',
        name: 'corporate-pretest-section-writing-test',
        component: () =>
          import('@/modules/corporateStudent/Pretests/Paid/pages/WritingTaskView.vue'),
        meta: {
          space: 'corporate_student'
        }
      },
      {
        path: '/corporate/pretests/paid/:userTestVariantId/result',
        component: () =>
          import('@/modules/corporateStudent/Pretests/Paid/pages/TestResultView.vue'),
        redirect: { name: 'corporate-student-pretest-result' },
        meta: {
          space: 'corporate_student'
        },
        children: [
          {
            path: 'general',
            name: 'corporate-student-pretest-result',
            component: () =>
              import(
                '@/modules/corporateStudent/Pretests/Paid/widgets/ui/ResultParts/GeneralResult.vue'
              )
          },
          {
            path: 'grammar',
            name: 'corporate-student-pretest-result-grammar',
            component: () =>
              import(
                '@/modules/corporateStudent/Pretests/Paid/widgets/ui/ResultParts/GrammarResult.vue'
              )
          },
          {
            path: 'writing',
            name: 'corporate-student-pretest-result-writing',
            component: () =>
              import(
                '@/modules/corporateStudent/Pretests/Paid/widgets/ui/ResultParts/WritingResult.vue'
              )
          },
          {
            path: 'speaking',
            name: 'corporate-student-pretest-result-speaking',
            component: () =>
              import(
                '@/modules/corporateStudent/Pretests/Paid/widgets/ui/ResultParts/SpeakingResult.vue'
              )
          },
          {
            path: 'certificate',
            name: 'corporate-student-pretest-result-certificate',
            component: () =>
              import(
                '@/modules/corporateStudent/Pretests/Paid/widgets/ui/ResultParts/ResultCertificate.vue'
              )
          }
        ]
      }
    ]
  },

  {
    path: '/corporate/student/groups',
    name: 'company-student-groups',
    component: () => import('@/modules/corporateStudent/Group/List/pages/GroupCoursesListView.vue'),
    meta: {
      space: 'corporate_student'
    }
  },
  {
    path: '/corporate/student/groups/:id',
    name: 'company-student-group-base-info',
    component: () => import('@/modules/corporateStudent/Group/General/pages/GroupGeneralView.vue'),
    meta: {
      space: 'corporate_student'
    }
  },
  {
    path: '/corporate/student/groups/schedule/:id',
    name: 'company-student-group-lessons',
    meta: {
      space: 'corporate_student'
    },
    component: () => import('@/modules/corporateStudent/Group/Schedule/pages/GroupScheduleView.vue')
  },
  {
    path: '/corporate/student/groups/schedule/:id/lesson/:lessonId',
    name: 'company-student-lesson',
    meta: {
      space: 'corporate_student'
    },
    component: () =>
      import('@/modules/corporateStudent/Group/Classwork/pages/GroupScheduleLessonView.vue')
  },
  {
    path: '/corporate/student/groups/:id/assigned-tasks',
    name: 'company-student-group-tasks',
    meta: {
      space: 'corporate_student'
    },
    component: () =>
      import('@/modules/corporateStudent/Group/AssignedTasks/pages/GroupAssignedTasksView.vue')
  },
  {
    path: '/corporate/student/groups/:id/assigned-tasks/:taskId',
    name: 'company-student-group-homework',
    meta: {
      space: 'corporate_student'
    },
    component: () =>
      import('@/modules/corporateStudent/Group/AssignedTasks/pages/AssignedTaskView.vue')
  },
  {
    path: '/corporate/student/groups/:id/summary',
    name: 'company-group-course-summary',
    meta: {
      space: 'corporate_student'
    },
    component: () =>
      import('@/modules/corporateStudent/Group/CourseSummary/pages/GroupSummaryView.vue')
  },
  {
    path: '/corporate/student/groups/:id/assigned-tasks/test/:taskId',
    name: 'company-student-group-test',
    meta: {
      space: 'corporate_student'
    },
    component: () =>
      import('@/modules/corporateStudent/Group/AssignedTasks/pages/AssignedTestView.vue')
  },
  {
    path: '/corporate/student/individuals',
    name: 'corporate-student-individuals',
    component: () =>
      import('@/modules/corporateStudent/Individuals/List/pages/IndividualsListView.vue'),
    meta: {
      space: 'corporate_student'
    }
  },
  {
    path: `/corporate/student/individuals/:id`,
    name: 'corporate-student-individuals-general',
    meta: {
      space: 'corporate_student'
    },
    component: () =>
      import('@/modules/corporateStudent/Individuals/General/pages/IndividualGeneralView.vue')
  },
  {
    path: `/corporate/student/individuals/schedule/:id`,
    name: 'corporate-student-individuals-schedule',
    meta: {
      space: 'corporate_student'
    },
    component: () =>
      import('@/modules/corporateStudent/Individuals/Schedule/pages/IndividualScheduleView.vue')
  },
  {
    path: '/corporate/student/individuals/schedule/:id/lesson/:lessonId',
    name: 'corporate-student-individuals-lesson',
    meta: {
      space: 'corporate_student'
    },
    component: () =>
      import('@/modules/corporateStudent/Individuals/Classwork/pages/IndividualLessonView.vue')
  },
  {
    path: '/corporate/student/clubs/upcoming',
    name: 'corporate-student-clubs',
    meta: {
      space: 'corporate_student'
    },
    component: () => import('@/modules/student/Club/pages/ClubsListView.vue')
  },
  {
    path: '/corporate/student/clubs/archived',
    name: 'corporate-student-clubs-archived',
    meta: {
      space: 'corporate_student'
    },
    component: () => import('@/modules/student/Club/pages/ClubsListView.vue')
  }
]
